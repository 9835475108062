@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-flex-container {
  display: flex;
  gap: 20px;
}

.ant-image-preview-img-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
}
  .custom-flex-container {
    flex-direction: column;
    align-items: left !important;
  }

  .ant-modal-body, .ant-modal-header, .ant-modal-content {
    border-radius: 10px !important;
}

.ant-btn-primary {
  background-color: rgb(96 165 250) !important;
}



.ant-skeleton-element {
  width: 100%;
}

/* Add this to your global CSS */
.ant-select-selector {
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-item {
  display: flex !important;
  justify-content: space-between !important;
  
}


